import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { Badge, Button, Divider, Flex, Heading, HStack, IconButton, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Stack, Text, Textarea, useDisclosure, useToast, Wrap } from '@chakra-ui/react'
import React from 'react'
import axiosInstance from '../../../utils/axios';
import { Prescription } from './NewOrder';
import SinglePrescription from './SinglePrescription';

export default function OrderItem({order, setOrders, index}: {order: any, setOrders: (orders: any) => void, index: number}) {

  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const [loading, setLoading] = React.useState(false);

  const setPrescriptions = (prescriptions: Prescription[]) => {

    // setOrders((oldOrders: any) => [...oldOrders.filter((o: any) => o.id !== order.id), {...order, prescriptions}]);
    setOrders((oldOrders: any) => {
        let newOrder = {...oldOrders};
        let newOrders = [...newOrder.orders];
        newOrders[index].prescriptions = prescriptions;
        newOrder.orders = newOrders;
        return newOrder;
    });

  }

  const createPrescription = () => {

    let prescription: Prescription = {
        person: {
            name: "No Name",
            age: "",
            gender: ""
        },
        drugs: []
    }

    let newOrder = {...order};
    newOrder.prescriptions = [...newOrder.prescriptions, prescription];
    setOrders((oldOrders: any) => {
        let newOrders = [...oldOrders.orders];
        newOrders[index] = newOrder;
        return {...oldOrders, orders: newOrders};
    });
  }

  const setAddress = (address: any) => {

    let newOrder = {...order};
    newOrder.address = address;
    setOrders((oldOrders: any) => {
        let newOrders = [...oldOrders.orders];
        newOrders[index] = newOrder;
        return {...oldOrders, orders: newOrders};
    });

  }

  const AddNote = () => {
    
        let newOrder = {...order};
        let newNotes = [...newOrder.notes];
        newNotes.push("");
        newOrder.notes = newNotes;
        setOrders((oldOrders: any) => {
            let newOrders = [...oldOrders.orders];
            newOrders[index] = newOrder;
            return {...oldOrders, orders: newOrders};
        });
    
  }

  const RemoveNote = (pos: number) => {

    let newOrder = {...order};
    let newNotes = [...newOrder.notes];
    newNotes.splice(pos, 1);
    newOrder.notes = newNotes;
    setOrders((oldOrders: any) => {
        let newOrders = [...oldOrders.orders];
        newOrders[index] = newOrder;
        return {...oldOrders, orders: newOrders};
    });


  }

  
  const checkoutAction = (action: string) => {

    setLoading(true);

   
    if(!order.currency){
        order.currency = "USD";
    }

    axiosInstance.post('/api/order/'+action, {...order, total: order.prescriptions.reduce((total: number, prescription: any ) => {
        prescription.drugs.forEach((drug: any) => {
            total += drug?.total || 0
        });
        return total;
    }, 0)})
    .then((res) => res.data)
    .then((data) => {
        
        let newOrder = {...data};
        setOrders((oldOrders: any) => {
            let newOrders = [...oldOrders.orders];
            newOrders[index] = newOrder;
            return {...oldOrders, orders: newOrders};
        });

        onClose();

        setLoading(false);
    })
    .catch((err) => {
        setLoading(false);
    })

  }

  return (
    <>
        <Stack p="2" borderRadius={"5px"} bg="white" cursor={"pointer"} onClick={onOpen}>
            <Flex justify={"space-between"}>
                <Text fontSize="xs" fontWeight={700}>#{order.uuid}</Text>
                <Text fontSize="xs">{order.createdAt.substring(0, 16).replace("T", " ")}</Text>
            </Flex>
            <Text fontSize="xs">{order.address.address}</Text>
            <Wrap>
                {order.status === 0 ? <Badge colorScheme="orange" p="1" fontSize="xs">Pending</Badge> : null}
                {order.status === 1 ? <Badge colorScheme="cyan" p="1" fontSize="xs">Quoted</Badge> : null}
                {order.status === 3 ? <Badge colorScheme="blue" p="1" fontSize="xs">Paid</Badge> : null}
                {order.status === 4 ? <Badge colorScheme="green" p="1" fontSize="xs">Delivered</Badge> : null}
                {order.status === 5 ? <Badge colorScheme="red" p="1" fontSize="xs">Cancelled</Badge> : null}
            </Wrap>
        </Stack>
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent bg="gray.100">
                <ModalHeader>
                    Order #{order.uuid} 
                    <Wrap>
                        {order.status === 0 ? <Badge colorScheme="orange" p="1" fontSize="xs">Pending</Badge> : null}
                        {order.status === 1 ? <Badge colorScheme="cyan" p="1" fontSize="xs">Quoted</Badge> : null}
                        {order.status === 3 ? <Badge colorScheme="blue" p="1" fontSize="xs">Paid</Badge> : null}
                        {order.status === 4 ? <Badge colorScheme="green" p="1" fontSize="xs">Delivered</Badge> : null}
                        {order.status === 5 ? <Badge colorScheme="red" p="1" fontSize="xs">Cancelled</Badge> : null}
                    </Wrap>
                </ModalHeader>
                <ModalCloseButton colorScheme={"red"} />
                 <ModalBody>
                    <Stack pb="5">
                        <HStack justify="space-between">
                            <Heading fontSize="xs" fontWeight="nornal">
                                You have {order.prescriptions.length} in your basket
                            </Heading>
                            <IconButton aria-label="" disabled={order.status > 2} colorScheme="primary" size="xs" onClick={createPrescription}>
                                <AddIcon />
                            </IconButton>
                        </HStack>
                        {order.prescriptions.map((prescription: Prescription, index: number) => (
                            <SinglePrescription prescription={prescription} prescriptions={order.prescriptions} order={order} setPrescriptions={setPrescriptions} key={index} index={index} price={true} />
                        ))}
                        {order.prescriptions.length > 0 &&  <Stack py="4">
                            <Heading fontSize="sm" fontWeight="nornal">
                                Delivery Details
                            </Heading>
                            <Divider />
                            <Input placeholder="Street Address" fontSize="xs" bg="white" value={order.address.address} onChange={(e) => setAddress({...order.address, address: e.target.value})}/>
                            <Input placeholder="Suburb" fontSize="xs" bg="white" value={order.address.suburb}  onChange={(e) => setAddress({...order.address, suburb: e.target.value})}/>
                            <Input placeholder="City" fontSize="xs" bg="white" value={order.address.city}  onChange={(e) => setAddress({...order.address, city: e.target.value})}/>
                            <Input placeholder="Special Requestions e.g call, hoot, intercom"fontSize="xs"  bg="white" value={order.address.special}   onChange={(e) => setAddress({...order.address, special: e.target.value})}/>
                        </Stack>}
                         <HStack justify={"space-between"}>
                            <Heading fontSize="sm" fontWeight="nornal">
                                    Notes
                            </Heading>
                            {order.status < 3 &&
                            <IconButton aria-label="" size="xs" onClick={AddNote} colorScheme="primary">
                                <AddIcon />
                            </IconButton>
                            }
                        </HStack>
                        

                        {order.notes.map((note: string, pos: number) => (
                            <HStack key={pos} align="start">
                                 <Textarea placeholder="Enter your note" fontSize="xs" bg="white" value={note} onChange={(e) => {
                                let newNotes = [...order.notes];
                                newNotes[pos] = e.target.value;
                                setOrders((oldOrders: any) => {
                                    let newOrders = [...oldOrders.orders];
                                    newOrders[index] = {...order, notes: newNotes};
                                    return {...oldOrders, orders: newOrders};
                                });
                                }} />
                                <IconButton colorScheme={"red"} disabled={order.status > 2} aria-label="" size="xs" onClick={() => RemoveNote(index)}>
                                    <MinusIcon />
                                </IconButton>
                            </HStack>
                           
                        ))}
                        
                        <Heading fontSize="sm" fontWeight="nornal">
                                Subtotal
                        </Heading>
                        <HStack>
                            <Input flexGrow={1} isDisabled={true} style={{opacity: 1}} fontSize="xs" opacity="1" bg="white" value={`$ ${order.prescriptions.reduce((total: number, prescription: any ) => {
                                prescription.drugs.forEach((drug: any) => {
                                    total += drug?.total || 0
                                });
                                return total;
                            }, 0)}`} />
                            <Select value={order.currency} disabled={order.status > 2} fontSize="xs" bg="white" onChange={(e) => {
                                let newOrder = {...order};
                                newOrder.currency = e.target.value;
                                setOrders((oldOrders: any) => {
                                    let newOrders = [...oldOrders.orders];
                                    newOrders[index] = newOrder;
                                    return {...oldOrders, orders: newOrders};
                                });
                            }}>
                                <option value="USD">USD</option>
                                <option value="ZWL">ZWL</option>
                            </Select>
                        </HStack>

                      
                        <HStack>
                            {order.status < 3 && <Button isLoading={loading} disabled={order.status > 2} colorScheme="red" onClick={() => checkoutAction('cancel')}>Cancel</Button>}
                            <Button isLoading={loading} disabled={order.status > 2} colorScheme="primary" onClick={() => checkoutAction('update')}>Update</Button>
                            <Button isLoading={loading}  colorScheme="green" onClick={() => checkoutAction('checkout')} disabled={order.prescriptions.reduce((total: number, prescription: any ) => {
                                prescription.drugs.forEach((drug: any) => {
                                    total += drug?.total || 0
                                });
                                return total;
                            }, 0) === 0 || order.status > 2}>Checkout</Button>
                        </HStack>
                    </Stack>
                 </ModalBody>

    
            </ModalContent>
      </Modal>
    </>
    
  )
}
