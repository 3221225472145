import { extendTheme } from "@chakra-ui/react";
const theme = extendTheme({
  styles: {
    global: (props: any) => ({
      "html, body": {
        fontSize: "16px",
        color: "#24292e",
        lineHeight: "1",
        backgroundColor: "#e7ecf8",
        height: "100%",
      },
      "h1, h2, h3, h4, h5, h6": {
        fontSize: "revert",
        color: "revert",
      },
      "#root": {
        height: "inherit",
      },
      "div::-webkit-scrollbar-thumb": {
        background: "transparent",
      },
      "button:focus, button:active, button:target": {
        boxShadow: "none !important",
      },
      messageBox: {
        boxShadow: "none !important",
      },
      ".emoji-mart-dark": {
        borderColor: "#676767",
        backgroundColor: "#1a202c",
      },
      ".emoji-mart-dark .emoji-mart-category-label span": {
        backgroundColor: "#171923",
        borderRadius: "11px",
      },
      ".emoji-mart-dark .emoji-mart-search input": {
        backgroundColor: "171923",
        marginBottom: "10px",
      },
      ".styles_r-howler__3PySh.styles_dark-themed__1Mmdx.styles_player__3QVPP":
        {
          backgroundColor: "#1a202c",
          borderRadius: "5px",
          boxShadow: "none",
        },
      ".styles_r-howler__3PySh.styles_light-themed__Pji_D.styles_player__3QVPP":
        {
          backgroundColor: "#edf2f7",
          borderRadius: "5px",
          boxShadow: "none !important",
        },
      ".mapboxgl-map": {
        borderRadius: "5px",
      },
      "canvas.snapshot-img-generator": {
        display: "none",
      },
      ".snapshot-img-generator": {
        maxWidth: "300px",
        opacity: "0.8",
        borderRadius: "5px",
      },
      ".snapshot-img-generator:hover": {
        opacity: "0.4",
      },
      ".react-video-thumbnail-image": {
        background: "black",
        borderRadius: "5px",
      },
      ".baseScreenshotimg": {
        width: "100%",
      },
    }),
  },
  colors: {
    primary: {
      100: "#267D9B",
      200: "#267D9B",
      300: "#267D9B",
      400: "#267D9B",
      500: "#267D9B",
      600: "#33A9D2",
      700: "#33A9D2",
      800: "#33A9D2",
      900: "#33A9D2",
    },
  },
});

export default theme;
