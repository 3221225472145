import axios from "axios";

const baseURL = process.env.REACT_APP_EXTERNAL_API || "http://localhost:5000";

let headers: any = {};
if (sessionStorage.token) {
  headers.Authorization = `${sessionStorage.token}`;
}

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers,
});

export default axiosInstance;
