import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Avatar, Box, Button, HStack, IconButton, Input, Stack, Text, Textarea, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { BiPencil, BiTrash } from 'react-icons/bi';

import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
  } from '@chakra-ui/react'
import axiosInstance from '../../utils/axios';

export default function QuickResponseItem({responses, response, setQuickResponses, pos} : {responses: any [], response: any, setQuickResponses: any, pos: number}) {

  const [loading, setLoading] = useState<boolean>(false);
  
  const [title, setTitle] = useState<string>("");
  const [body, setBody] = useState<string>("");

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if(response){
        setTitle(response.title);
        setBody(response.body);
    }
  }, [response]);

  const saveResponse = async() => {
    setLoading(true);
    axiosInstance.put('/api/quickresponses', {uuid: response.uuid, title, body})
    .then((res) => res.data)
    .then((data) => {
        setLoading(false);
        setTitle("");
        setBody("");
        let oldResponses = [...responses];
        oldResponses.forEach((res, index) => {
            if(res.uuid === response.uuid){
                oldResponses[index] = {...oldResponses[index], title, body}
            }
        });
        setQuickResponses(oldResponses);
        onClose();
        //setQuickResponses((old: any []) => [...old, data]);
    })
    .catch((err) => {
        setLoading(false);
    })
  }

  const deleteResponse = async() => {
    setLoading(true);
    axiosInstance.delete('/api/quickResponses/'+response.uuid)
    .then((res) => res.data)
    .then((data) => {

        setLoading(true);
        let newResponses = [...responses];
        //splice
        onClose();
        newResponses.splice(pos, 1);

        setQuickResponses(newResponses);
    })
    .catch((err) => {
        setLoading(true);
    });
  }


  return (
    <>
        <HStack bg="gray.100" border="1px solid rgba(0,0,0,0.1)" boxShadow="lg" p="1" px="2" borderRadius="30px">
            <Avatar size="xs" bg="cyan.100" border="1px solid cyan.300" color="cyan.700" name={(pos + 1).toString()}/>
            <Text fontSize="0.9rem" flexGrow="1">{response.title}</Text>
            <IconButton aria-label='' size="sm" onClick={onOpen}>
                <BiPencil />
            </IconButton>
            <IconButton aria-label='' size="sm" isLoading={loading} disabled={loading} onClick={deleteResponse}>
                <BiTrash />
            </IconButton>
        </HStack>

        <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Update QR</DrawerHeader>

          <DrawerBody>
            <Stack h="100%">
                <Input placeholder="Searchable Title" borderColor="primary.100" fontSize="sm" value={title} onChange={(e:any) => {
                        setTitle(e.target.value);
                    }} />
                <Textarea h="100%" flexGrow="1" placeholder="Quick response text"  borderColor="primary.100" fontSize="sm"value={body} onChange={(e:any) => {
                    setBody(e.target.value);
                }} />
                <Box>
                    <Button colorScheme={"cyan"} size="sm" isLoading={loading} disabled={!title || !body} onClick={saveResponse}>Update</Button>
                </Box>
            </Stack>
               
          </DrawerBody>

         
        </DrawerContent>
      </Drawer>
    </>
  )
}
