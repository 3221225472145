import { Avatar, AvatarBadge, Box, HStack, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { BsCheck2, BsCheck2All } from 'react-icons/bs';
import { Link } from 'react-router-dom';

export default function ChatListItem({chatItem}: {chatItem: any}) {


  const [lastMessage, setLastMessage] = useState<any>(undefined);  

  useEffect(() => {


    let lastMsg = chatItem?.Messages[0]?.body[chatItem?.Messages[0]?.body?.length - 1] || null;
    setLastMessage(lastMsg)

  },[chatItem]);


  return (
    <Link
    to={`/chat/${chatItem.phone}`}
  >
    <HStack
      py="5"
      px="3"
      spacing="3"
      cursor="pointer"
      _hover={{ bg: useColorModeValue("blue.50", "gray.900") }}
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      borderBottomWidth="1px"
      transition="all 0.35s ease-in-out"
    >
      <Box>
        <Avatar src={chatItem.name} size="sm" borderColor="white">
          <AvatarBadge
            bg="green.300"
            boxSize="1.25em"
            fontSize="0.55em"
            shadow="md"
            p="8px"
            top="-2"
            color="gray.700"
          >
            {chatItem.unread}
          </AvatarBadge>
        </Avatar>
      </Box>
      <Stack flexGrow="1" spacing="2px">
        <HStack justify="space-between">
          <Text
            fontSize="0.85em"
            color={useColorModeValue("gray.700", "gray.50")}
            fontWeight="600"
          >
            {chatItem.name || chatItem.phone}
          </Text>

          <Text
            fontSize="0.8em"
            display={chatItem.unread ? "initial" : "none"}
            color={useColorModeValue("gray.600", "gray.500")}
          >
            {/* {istoday && date.getHours() + ":" + date.getMinutes() + " Hrs"}
            {isyesterday && "Yesterday"}
            {!istoday &&
              !isyesterday &&
              date.getDate() +
                "/" +
                (date.getMonth() + 1) +
                "/" +
                date.getFullYear()} */}
          </Text>
        </HStack>
        <HStack>
          {lastMessage?.direction === "outbound-api" &&
            lastMessage?.status === "sent" && <BsCheck2 />}
          {lastMessage?.direction === "outbound-api" &&
            lastMessage?.status === "delivered" && <BsCheck2All />}
          {lastMessage?.direction &&
            lastMessage?.statu === "viewed" && (
              <BsCheck2All color="rgb(0, 149, 255)" />
            )}

          {/* {chat?.lastMessage?.type === "video" && (
            <BsCameraVideo size="10px" />
          )}
          {chat?.lastMessage?.type === "location" && <BsPinMap size="10px" />}
          {chat?.lastMessage?.type === "ppt" && <BsMusicNote size="10px" />}
          {chat?.lastMessage?.type === "audio" && <BsMusicNote size="10px" />}
          {chat?.lastMessage?.type === "image" && <BsImageAlt size="10px" />}
          {chat?.lastMessage?.type === "document" && (
            <BsDownload size="10px" />
          )}
          {chat?.lastMessage?.type === "call_log" && (
            <BsTelephoneForward size="10px" />
          )} */}
          <Text
            fontSize="0.75em"
            color={useColorModeValue("gray.600", "gray.500")}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "150px",
            }}
          >
            {(lastMessage && lastMessage?.Body) || (lastMessage && lastMessage?.body) || ""}
            {/* {chat?.lastMessage?.type === "chat" && chat?.lastMessage?.body}
            {chat?.lastMessage?.type === "note" && chat?.lastMessage?.body}
            {chat?.lastMessage?.type === "video" && "Video"}
            {chat?.lastMessage?.type === "location" && "Location"}
            {chat?.lastMessage?.type === "ppt" && "Audio"}
            {chat?.lastMessage?.type === "audio" && "Audio"}
            {chat?.lastMessage?.type === "image" && "Image"}
            {chat?.lastMessage?.type === "document" && "Document"}
            {chat?.lastMessage?.type === "call_log" && "Call"} */}
          </Text>
        </HStack>
        {/* <HStack justify="space-between">
          <AvatarGroup size="xs" max={2}>
            <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
            <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
            <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
            <Avatar
              name="Prosper Otemuyiwa"
              src="https://bit.ly/prosper-baba"
            />
            <Avatar name="Christian Nwamba" src="https://bit.ly/code-beast" />
          </AvatarGroup>
        </HStack> */}
      </Stack>
    </HStack>
  </Link>
  )
}
