import { CloseIcon } from "@chakra-ui/icons";
import { Box, Collapse, Heading, HStack, IconButton, Stack, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import EmojiPicker from "emoji-picker-react";
import { useEffect, useState } from "react";
import { BsEmojiSmile } from "react-icons/bs";
import { MdSend } from "react-icons/md";
import ReactTextareaAutosize from "react-textarea-autosize";
import axiosInstance from "../../utils/axios";
import Attach from "./Attach";
// @ts-ignore
import Editor from "screenshot-editor-test";
// @ts-ignore
import Player from "react-howler-player";
import ReactPlayer from "react-player";

function SendBox({ waId, addMessage } : {waId: any, addMessage: any}) {
    const [openEmoji, setOpenEmoji] = useState(false);
    const toast = useToast();

    const [body, setBody] = useState("");
    const [file, setFile] = useState<any>(undefined);
    const [fileType, setFileType] = useState(undefined);

    const [quickResponses, setQuickResponses] = useState([]);
    

    //  Sending Messages Logic
    const [loadingSend, setLoadingSend] = useState(false);
    const sendMessage = async() => {

        if(!body && !file){
            toast({
                description: "Message body or file required!",
                status: "error"
            });
            return;
        }
        setLoadingSend(true);

        await  axiosInstance.post("/api/message/"+waId, {
            body, file
        })
        .then((res) => res.data)
        .then((data) => {
            // addMessage(data);            
            setBody("");
            setFile(undefined);
            setFileType(undefined);
        })
        .catch((err) => console.log(err));

        setLoadingSend(false);
    }


    useEffect(() => {
      axiosInstance.get('/api/quickresponses')
      .then((res) => res.data)
      .then((data) => {
          setQuickResponses(data)
      })
      .catch((err) => {
      })

    }, []);

    const sendQuickReply = (res: any) => {
      setBody(res.body);
    }




  return (
    <Box
      position="sticky"
      bottom="0"
      // bg="white"
      zIndex="2"
      bg={useColorModeValue("gray.200", "gray.800")}
    >
      <Stack py="3" px="3">
      <HStack align="start" color={useColorModeValue("gray.600", "gray.50")}>
          {file && (
            <>
              <Box
                shadow="lg"
                borderRadius="lg"
                overflow="hidden"
                maxW="50%"
                w="100%"
              >
                {file.type.includes("image/") && (
                  <Editor
                    imgSrc={file.base64}
                    width="100px"
                    onDrawingEnd={(e: any) => {
                      setFile({ ...file, base64: e });
                    }}
                    lineWidth={5}
                  />
                )}
                {file.type.includes("video/") && (
                  <ReactPlayer width="100%" controls={true} url={file.base64} />
                )}
                {file.type.includes("audio/") && (
                  <Player
                    style={{ boxShadow: "none" }}
                    src={[file.base64]}
                    isDark={false}
                  />
                )}
              </Box>
              <IconButton
                aria-label=""
                icon={<CloseIcon shadow="lg" />}
                onClick={() => setFile(null)}
                colorScheme="red"
                size="sm"
              />
            </>
          )}
        </HStack>
        <Collapse in={openEmoji} animateOpacity>
          <EmojiPicker
            pickerStyle={{width: "100%"}}
            onEmojiClick={(e, data) => {
                setBody((old) => old + data.emoji)
            }}
            // theme={useColorModeValue("light", "dark")}
            // style={{ width: "100%" }}
            // title="Pick your emoji…"
            // emoji="point_up"
            // skin={1}
            // showPreview={false}
            // showSkinTones={false}
            // onSelect={(e) => {
            //   setMessageText(messageText + "" + e.native);
            // }}
          />
        </Collapse>
          
        
        <Stack>

          {body.startsWith("/") && <>
            <Heading size="xs">/ Quick Responses</Heading>
          </>}

          {body.startsWith("/")  && body.length < 4 && (
            <Text color="red.500" fontSize="xs">Enter atlist {4 - body.length} more characters </Text>
          )}
          {body.startsWith("/") 
          && body.length > 3
          && quickResponses.map((res: any) => {
            if(res.title.toLowerCase().includes(body.toLowerCase().substring(1))){
              return (
                <Stack>
                  <HStack p="2" boxShadow="md" bg="gray.50" borderLeft={"1px solid green"} borderRadius="2px" _hover={{bg: "cyan.50"}} cursor="pointer" onClick={() => sendQuickReply(res)}>
                   <Text fontSize="xs">{res.title}</Text>
                  </HStack>
                </Stack>
              )
            }
          })}
        </Stack>
        
        <HStack spacing="5px">
         
          <IconButton
            aria-label="Open Emoji"
            onClick={() => {
              setOpenEmoji(true);
            }}
            bg={useColorModeValue("yellow.300", "yellow.400")}
            _hover={{
              color: useColorModeValue("gray.800", "gray.300"),
              bg: useColorModeValue("yellow.400", "gray.500"),
            }}
            size="sm"
            color={useColorModeValue("gray.700", "gray.800")}
            icon={<BsEmojiSmile size="18px" />}
          />

          <IconButton
            aria-label="Close Icon"
            display={openEmoji ? "flex" : "none"}
            onClick={() => {
              setOpenEmoji(false);
            }}
            transition="all 0.35s ease-in-out"
            size="sm"
            colorScheme="red"
            icon={<CloseIcon fontSize="18px" />}
          />
         
          <Attach setFile={setFile} setType={setFileType} />
          <Stack flexGrow="1">
            {/* <Stack maxH="300px" h="100%" overflowY="scroll">
                <HStack>
                  <Text
                    fontSize="0.8em"
                    color={useColorModeValue("gray.600", "gray.300")}
                  >
                    QUICK REPLIES
                  </Text>
                </HStack>
                <Stack spacing="10px">
                  <Text
                    cursor="pointer"
                    fontSize="0.8em"
                    color={useColorModeValue("gray.700", "gray.200")}
                  >
                    / Test Quick Reply
                  </Text>
                  <Text
                    cursor="pointer"
                    fontSize="0.8em"
                    color={useColorModeValue("gray.700", "gray.200")}
                  >
                    / Test Quick Reply 3
                  </Text>
                </Stack>
              </Stack> */}
            <ReactTextareaAutosize
              autoFocus
              maxRows={3}
              minRows={1}
              className="messageBox"
              placeholder="Type a message..."
              value={body}
              onChange={(e) => {
                setBody(e.target.value);
                setOpenEmoji(false);
              }}
              style={{
                width: "100%",
                borderRadius: "5px",
                padding: "13px",
                background: `${useColorModeValue("white", "#171923")}`,
                boxShadow: "none",
                outline: "none",
                lineHeight: "1.2",
                letterSpacing: "1px",
                color: `${useColorModeValue("#171923", "white")}`,
                fontSize:"0.8em"
              }}
            />
          </Stack>
          <Box color={useColorModeValue("gray.600", "gray.500")}>
            <IconButton
              aria-label=""
              isLoading={loadingSend}
              onClick={sendMessage}
              size="sm"
              icon={<MdSend size="18px" />}
            />
          </Box>
        </HStack>
      </Stack>
    </Box>
  );
}

export default SendBox;
