import { Center, Icon, Stack } from '@chakra-ui/react'
import { SiHackthebox } from 'react-icons/si'
import React from 'react'

export default function NotFound() {
  return (
    <Center>
      <Stack>
        <SiHackthebox size={"100px"} color="rgba(0,0,0,.2)" />
      </Stack>
    </Center>
  )
}
