import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import AppLayout from './pages/AppLayout';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import { AuthProvider } from './utils/auth/AuthContext';
import { ProtectedRoute } from './utils/auth/ProtectedRoute';

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
            <Route path="/*" element={
                <ProtectedRoute>
                  <AppLayout />
                </ProtectedRoute>
              }
            />
            <Route path="/auth" element={<Login />}/>
            <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
    
  );
}

export default App;
