import { useColorModeValue } from "@chakra-ui/color-mode";
import { Image } from "@chakra-ui/image";
import { Box, Flex, Link, Stack, Text, HStack } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import { BsCheck2, BsCheck2All, BsClock, BsReply } from "react-icons/bs";
import { useDispatch } from "react-redux";
// @ts-ignore
import Player from "react-howler-player";
import { FaDownload, FaFileAudio, FaFileVideo, FaSpeakerDeck } from "react-icons/fa";

import { IconButton } from "@chakra-ui/button";

import { MdAudiotrack } from "react-icons/md";

function SingleChatMessage({ chat }: {chat: any}) {

  let dr = chat?.direction === "outbound-api" ? "right" : "left";
  const direction = {
    [dr]: chat?.direction === "outbound-api" ? "-8px" : "-8px",
  };
  const br = {
    [chat?.direction === "outbound-api" ? "borderTopRightRadius" : "borderTopLeftRadius"]: "0px",
  };


  const today = new Date();


  useEffect(() => {
    // let ndate = new Date(chat.lastMessage ? parseInt(chat.time * 1000) : "now");
    // setDate(ndate);
    // if (
    //   yesterday.getDay() === ndate.getDay() &&
    //   yesterday.getMonth() === ndate.getMonth() &&
    //   yesterday.getFullYear() === ndate.getFullYear()
    // ) {
    //   let yesterday = true;
    //   setIsToday(false);
    //   setIsYesterDay(true);
    // } else if (
    //   today.getDay() === ndate.getDay() &&
    //   today.getMonth() === ndate.getMonth() &&
    //   today.getFullYear() === ndate.getFullYear()
    // ) {
    //   let yesterday = false;
    //   setIsToday(true);
    // } else {
    //   let istoday = false;
    // }
  }, [chat]);

  const width = () => {
    // if (chat.type === "video") {
    //   return "300px";
    // } else if (chat.type === "image") {
    //   return "300px";
    // } else {
    //   return "65%";
    // }
    return "65%";
  };

  const dispatch = useDispatch();

  return (
    <>
      <Flex
        px="8"
        className={chat?.direction === "outbound-api" ? "right-bubble" : "left-bubble"}
        justify={chat?.direction === "outbound-api" ? "end" : "start"}
      >
        <Stack
          w="auto"
          maxW={width()}
          minW="200px"
          spacing="5px"
          position="relative"
          role="group"
        >
          <IconButton
            zIndex="2"
            top="2"
            right="1"
            size="xs"
            colorScheme="yellow"
            visibility="hidden"
            transition="all 0.3s ease-in-out"
            aria-label=""
            // _groupHover={{ visibility: "visible" }}
            position="absolute"
            onClick={() => {
            //   dispatch(setReply(chat));
            }}
          >
            <BsReply />
          </IconButton>
          <Box
            p="1"
            bg={useColorModeValue(
              chat?.direction === "outbound-api" ? "primary.700" : "white",
              chat?.direction === "outbound-api" ? "primary.600" : "gray.700"
            )}
            borderRadius="5px"
            {...br}
            position="relative"
            shadow="sm"
          >
            <Box
              position="absolute"
              color={useColorModeValue(
                chat?.direction === "outbound-api" ? "primary.700" : "white",
                chat?.direction === "outbound-api" ? "primary.600" : "gray.700"
              )}
              {...direction}
              // left="-8px"
              top="0"
            >
              {chat?.direction === "outbound-api" ? (
                <svg viewBox="0 0 8 13" width="8" height="13">
                  <path
                    opacity=".13"
                    d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"
                  ></path>
                  <path
                    fill="currentColor"
                    d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"
                  ></path>
                </svg>
              ) : (
                <svg viewBox="0 0 8 13" width="8" height="13">
                  <path
                    opacity=".13"
                    fill="currentColor"
                    d="M1.533 3.568L8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z"
                  ></path>
                  <path
                    fill="currentColor"
                    d="M1.533 2.568L8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z"
                  ></path>
                </svg>
              )}
            </Box>
            <Stack
              color={useColorModeValue(
                chat?.direction === "outbound-api" ? "gray.50" : "gray.700",
                chat?.direction === "outbound-api"? "white" : "gray.50"
              )}
            >
              {/* {data.quotedMsgId && data.quotedMsgId.length > 0 && (
                <Stack
                  bg={colorMode ? "gray.900" : "gray.100"}
                  p="2"
                  borderRadius="5px"
                  borderLeftWidth="4px"
                  borderLeftColor="red.400"
                  spacing="3px"
                  color="gray.500"
                  display={
                    data.quotedMsgId && data.quotedMsgId.length > 0
                      ? "block"
                      : "none"
                  }
                >
                  {data.quotedMsgType === "chat" && (
                    <Text fontSize="0.7em" color={"gray.500"}>
                      {data.quotedMsgBody}
                    </Text>
                  )}
                  {data.quotedMsgType === "video" && <FaFileVideo />}
                  {data.quotedMsgType === "audio" && (
                    <HStack>
                      <MdAudiotrack />
                      <Text>Audio</Text>
                    </HStack>
                  )}
                </Stack>
              )} */}

              {"MediaContentType0" in chat && chat.MediaContentType0 === "image/jpeg" && (
                <Image
                  maxW="300px"
                  borderRadius="5px"
                  src={chat.MediaUrl0}
                  fallbackSrc=""
                />
              )}

              {/* {chat.type === "location" && (
                <Link
                  target="_blank"
                  href={`https://www.google.com/maps/search/?api=1&query=${chat.body.replace(
                    ";",
                    ","
                  )}`}
                >
                  <Image
                    src={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/geojson(%7B%22type%22:%22Point%22,%22coordinates%22:[${
                      chat.Body.split(";")[1]
                    },${chat.body.split(";")[0]}]%7D)/${
                      chat.body.split(";")[1]
                    },${
                      chat.body.split(";")[0]
                    },12/500x300?access_token=pk.eyJ1IjoiaWFuYWxlY2siLCJhIjoiY2t2eGIzeXl2MDNsZDJ2bm93bDFhNzZ0eSJ9.yvw9FPfRGD0NIrOaPoEO8g`}
                    maxW="300px"
                    borderRadius="md"
                  />
                </Link>
              )} */}
              <Text
                p="1"
                fontSize="0.90em"
                lineHeight="1.3"
                style={{ whiteSpace: "pre-line" }}
                color={useColorModeValue(
                  chat?.direction === "outbound-api" ? "gray.50" : "gray.700",
                  chat?.direction === "outbound-api" ? "white" : "gray.50"
                )}
              >
                {/* {chat.agent_name}  */}
                {chat.Body || chat.body || ""}
              </Text>

              {("MediaContentType0" in chat && chat.MediaContentType0 === "audio/mpeg") && (
                <Player
                  style={{ boxShadow: "none" }}
                  src={[chat.MediaUrl0]}
                  isDark={false}
                />
              )}

              {/* {chat.type === "video" && chat.body && (
                <VideoPlayer islight={!colorMode} chat={chat} />
              )}
              {chat.type === "vcard" && <VCard chat={chat} />} */}

              <Flex justify="end">
                <Text
                  fontSize="0.70em"
                  color={useColorModeValue(
                    chat?.direction === "outbound-api" ? "gray.50" : "gray.600",
                    chat?.direction === "outbound-api" ? "white" : "gray.400"
                  )}
                >
                  {chat?.createdAt
                    ?.substring(2, 16)
                    .replace("T", " ")
                    .replaceAll("-", "/")}
                </Text>
              </Flex>
            </Stack>
          </Box>
          {chat?.direction === "outbound-api" && (
            <Flex justify="end" color="gray.500">
              {chat.status === "sent" && <BsCheck2 />}
              {chat.status === "delivered" && <BsCheck2All />}
              {chat.status === "viewed" && <BsCheck2All color="#0095ff" />}
              {chat.status === "sending" && <BsClock />}
            </Flex>
          )}
          {"MediaContentType0" in chat && chat.MediaContentType0.includes("application/") && <a href={chat.MediaUrl0} download>
                <IconButton size="xs" aria-label="Download"><FaDownload /></IconButton></a>}
        </Stack>
      </Flex>
    </>
  );
}

export default SingleChatMessage;
