import { Box, Button, Heading, HStack, Stack, Table, Thead, Tr, Td, Th, Tbody, Checkbox, Badge, Center, Spinner, Text, Wrap, Input, Select, InputGroup, InputRightAddon, InputRightElement, IconButton, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import axiosInstance from '../utils/axios';
import NotFound from './NotFound';
import { addDays, endOfDay, setDate, startOfDay, subDays } from 'date-fns';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
  } from '@chakra-ui/react'
// @ts-ignore
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
// ignore typescript import error
// @ts-ignore
import { debounce } from 'throttle-debounce';
import { CalendarIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

export default function OrdersPage() {

 
  const navigate = useNavigate();

  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [query, setQuery] = useState<string>('');
  const [status, setStatus] = useState<any>(undefined);
  const [orders, setOrders] = useState<any>([]);
  const [selected, setSelected] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const { onOpen, onClose, isOpen } = useDisclosure();

  const [dateRange, setDateRange] = useState(
    [
        {
            startDate: startOfDay(subDays(new Date(), 30)),
            endDate: new Date(),
            key: 'selection'
        }
    ]
  );


  const debounceQuery = debounce(1000, (q: any) => {
	setQuery(q);
  });

  const toggleSelected = () => {

    if (selected.length  === orders.length) {
      setSelected([]);
    } else {
      setSelected(orders.map((order: any) => order.id));
    }

  }

  useEffect(() => {

    if(page > 0){
        search(page, dateRange[0].startDate, dateRange[0].endDate); 
    }

  }, [page]);

  useEffect(() => {

    setOrders([]);

    setPage(1);
    search(page, dateRange[0].startDate, dateRange[0].endDate); 

  }, [query, status]);

  const search = (p: any, start: any, end: any) => {
    setLoading(true);

    onClose();

    axiosInstance.post("/api/orders", {
        page: p,
        status,
        query,
        range:  [start, end]
    })
    .then((res) => res.data)
    .then((data) => {
        setLoading(false);
        setOrders(page === 1 ? data.results : [...orders, ...data.results]);
        setTotal(data.count);
    }).catch((err) => {
        console.log(err);
        setLoading(false);
    });
}

  return (
    <Box p={{base: "2", md: "10"}} w="100%" overflow={"auto"} maxH="100vh" >
        <Stack spacing={{base: "3", md: 3}} h="100%">
            <HStack w="100%" justify={"space-between"}>
                <Heading size="2.5em">Orders</Heading>
                <Button colorScheme="primary">Add New</Button>
            </HStack>
            <HStack py="5"  color="gray.500" fontSize={"sm"} justify="space-between">
                <Text>
                    Showing {orders.length} of {total} orders
                </Text>
                <Text>
                    {dateRange[0].startDate.toLocaleDateString()} to {dateRange[0].endDate.toLocaleDateString()}
                </Text>
            </HStack>
          

                <HStack>
                    <InputGroup>
                        <Input onChange={(e) => {
                            debounceQuery(e.target.value);
                        }} placeholder='Search....' bg="white" flexGrow="1" />
                        {loading &&  <InputRightElement>
                            <Spinner />
                        </InputRightElement>}
                       
                    </InputGroup>
                    <Select flexShrink={3} bg="white">
                        <option value=''>All</option>
                        <option value='0'>Pending</option>
                        <option value='1'>Quoted</option>
                        <option value='3'>Paid</option>
                        <option value='4'>Delivered</option>
                        <option value='5'>Cancelled</option>
                    </Select>
                    <Popover isOpen={isOpen}>
                            <PopoverTrigger>
                            <IconButton aria-label='' onClick={onOpen}>
                                <CalendarIcon />
                            </IconButton>
                            </PopoverTrigger>

                        <PopoverContent w="100%">
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverHeader>Select Date Range</PopoverHeader>
                                <PopoverBody> 
                                    <DateRangePicker
                                                    ranges={dateRange}
                                                    onChange={(range: any) => {
                                                        setDateRange([range.selection])
                                                        // check if the start date is after the end date
                                                        if (range.selection.startDate !== range.selection.endDate) {
                                                            setOrders([]);
                                                            setPage(1);
                                                            search(1, startOfDay(new Date(range.selection.startDate)), endOfDay(new Date(range.selection.endDate))); 
                                                        }

                                                    }}
                                    />
                                </PopoverBody>
                        </PopoverContent>
                    </Popover>
                </HStack>
                <Table variant='striped'  h={"100%"}>
                    {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
                    <Thead position={"sticky"} top="0" bg="white" zIndex="9" shadow="md" borderRadius={"md"} overflow="hidden">
                        <Tr>
                            <Th>
                                <Checkbox isChecked={selected.length === orders.length} onChange={toggleSelected} />
                            </Th>
                            <Th>Order #</Th>
                            <Th>Client</Th>
                            <Th>Status</Th>
                            <Th isNumeric>Total</Th>
                        </Tr>
                    </Thead>
                    <Tbody h="100%" maxH={"100%"} overflow={"scroll"}>
                       { 
                        orders.map((order: any) => (
                            <Tr key={order.id}  _hover={{bg: "white", boxShadow: "md"}} cursor="pointer" onClick={() => navigate("/order/"+order.uuid)}>
                                <Td w="10px">
                                    <Checkbox isChecked={selected.includes(order.id)} onChange={(e) => {
                                        if (selected.includes(order.id)) {
                                            setSelected(selected.filter((id: any) => id !== order.id));
                                        } else {
                                            setSelected([...selected, order.id]);
                                        }
                                    }} />
                                </Td>
                                <Td maxW="150px">
                                    <Stack spacing="0">
                                        <Text>{order.uuid}</Text>
                                        <Text fontSize={"xs"}>{order.createdAt}</Text>
                                    </Stack>
                                </Td>
                                <Td maxW="100px">
                                    {order.waId}
                                </Td>
                                <Td w="50px">
                                    <Wrap>
                                        {order.status === 0 ? <Badge colorScheme="orange" p="1" fontSize="xs">Pending</Badge> : null}
                                        {order.status === 1 ? <Badge colorScheme="cyan" p="1" fontSize="xs">Quoted</Badge> : null}
                                        {order.status === 3 ? <Badge colorScheme="blue" p="1" fontSize="xs">Paid</Badge> : null}
                                        {order.status === 4 ? <Badge colorScheme="green" p="1" fontSize="xs">Delivered</Badge> : null}
                                        {order.status === 5 ? <Badge colorScheme="red" p="1" fontSize="xs">Cancelled</Badge> : null}
                                    </Wrap>
                                </Td>
                                <Td isNumeric>
                                    <HStack align={"end"} justify="end">
                                        <Text fontWeight={"700"}>{order.total}</Text>
                                        <Text fontSize={"xs"}>{order.currency}</Text>
                                    </HStack>
                                </Td>
                            </Tr>
                        ))
                       } 
                    </Tbody>
                </Table>
                <Center>
                    {loading  && <Spinner color={"cyan.800"} />}

                    {!loading && orders.length === 0 ? <Stack py="10" bg="gray.100" w="100%" align="center" borderRadius={"10px"}>
                            <NotFound />
                            <Heading size="2.5em" fontWeight={"400"}>No orders found</Heading>
                        </Stack> : null}

                    {!loading && orders.length > 0 && total > orders.length &&  <Button colorScheme="primary" onClick={() => setPage(current => current+1)}>Load More</Button>}
                   
                </Center>

        </Stack>
    </Box>
  )
}
