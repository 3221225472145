import { AddIcon, PlusSquareIcon } from '@chakra-ui/icons';
import {  Button, Divider, Heading, HStack, IconButton, Input, Stack, Text } from '@chakra-ui/react'
import  { useState } from 'react'
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../utils/axios';
import SendDraft from './SendDraft';
import SinglePrescription from './SinglePrescription';

export interface Prescription {
    person: {
        name: string,
        age?: string,
        gender?: string
    },
    drugs: [
        {
            title: string,
            type: string,
            dosage: string,
            total?: number
        }?
    ]
}

export default function NewOrder({setOrders, orders} : {setOrders: any, orders: any}) {

  const {waId} = useParams();  

  const [prescriptions, setPrescriptions] = useState<Prescription[]>([]);
  const [loading, setLoading] = useState(false);

  const createPrescription = () => {

    let prescription: Prescription = {
        person: {
            name: "No Name",
            age: "",
            gender: ""
        },
        drugs: []
    }

    setPrescriptions((oldPrescriptions: Prescription[]) => [...oldPrescriptions, prescription]);
  }

  const [address, setAddress] = useState({
    address: "",
    suburb: "",
    city: "",
    special: ""
  });


  const createOrder = () => {

    setLoading(true);

    axiosInstance.post("/api/order", {
        prescriptions,
        waId,
        address
    })
    .then((res) => res.data)
    .then((data) => {
        console.log(data);
      
        let newOrder = {...orders};
        newOrder.orders = [{...data}, ...newOrder.orders];
        newOrder.count++;
        setOrders(newOrder);

        setPrescriptions([]);
        setAddress({
            address: "",
            suburb: "",
            city: "",
            special: ""
        });
       
        //setOrders((old: any) => [...old, data]);
        // setPrescriptions([]);
        setLoading(false);
    })
    .catch((err) => {
        setLoading(false);
    })

  }

  

  return (
    <Stack pt="5">
        <Stack spacing="1">
            <Heading size="sm" mb={0}>Create new presciption(s)</Heading>
            <Text fontSize="xs" opacity="0.8">You can generate draft prescription(s)</Text>
        </Stack>
        <Divider />
        <HStack justify="space-between">
            <Heading fontSize="xs" fontWeight="nornal">
                You have {prescriptions.length} in your draft
            </Heading>
            <IconButton aria-label="" colorScheme="primary" size="xs" onClick={createPrescription}>
                <AddIcon />
            </IconButton>
        </HStack>
        {prescriptions.map((prescription: Prescription, index: number) => (
            <SinglePrescription prescription={prescription} prescriptions={prescriptions} order={undefined} setPrescriptions={setPrescriptions} key={index} index={index} price={false} />
        ))}
        <SendDraft prescriptions={prescriptions}  address={address}/>
        {prescriptions.length > 0 &&  <Stack py="4">
            <Heading fontSize="sm" fontWeight="nornal">
                Delivery Details
            </Heading>
            <Divider />
            <Input placeholder="Street Address" fontSize="xs" bg="white" value={address.address} onChange={(e) => setAddress({...address, address: e.target.value})}/>
            <Input placeholder="Suburb" fontSize="xs" bg="white" value={address.suburb}  onChange={(e) => setAddress({...address, suburb: e.target.value})}/>
            <Input placeholder="City" fontSize="xs" bg="white" value={address.city}  onChange={(e) => setAddress({...address, city: e.target.value})}/>
            <Input placeholder="Special Requestions e.g call, hoot, intercom"fontSize="xs"  bg="white" value={address.special}   onChange={(e) => setAddress({...address, special: e.target.value})}/>
        </Stack>}
       
        <Button isLoading={loading} disabled={!prescriptions.length} leftIcon={<PlusSquareIcon />} onClick={createOrder} colorScheme="green" size="sm">Create Order</Button>
    </Stack>
  )
}
