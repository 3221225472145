import React, { useState, useEffect, useContext, createContext } from "react";
import { useDispatch } from "react-redux";

import io from "socket.io-client";
import { setConversations } from "../redux/conversationsSlice";
//initialize firebase
let socket: any = undefined;
const SocketContext = createContext(undefined);

// Hook for child components to get auth ojbect....
// ... and re-render when it changes
export const useSocket = () => {
  return useContext(SocketContext);
};

//Provider hook that creates auth object and haldes state
export const SocketProvider = ({ children }: {children: JSX.Element}) => {
  const [connected, setConnected] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    connect();
  }, []);
  // useEffect(() => {

  //   // socket.emit("joinChat", "263771564110", { name: "Ian" }, (response) => {
  //   //   console.log(response.status); // ok
  //   // });
  //   // socket.on("chat", (data) => {
  //   //   console.log(data);
  //   // });

  //   return () => {
  //     setConnected(false);
  //     socket.disconnect();
  //     socket.off();
  //   };
  // }, []);

  const connect = () => {
    socket = io(process.env.REACT_APP_SOCKET || "http://localhost:5000", {
      path: "/chat",
    });
    socket.on("connect", () => {
      setConnected(true);
    });
    socket.on("pool", (data: any) => {
        dispatch(setConversations({...data}));
    });
  };

  const disconnect = () => {
    setConnected(false);
    socket.disconnect();
    socket.off();
  };

  //check if current user is administrator

  const values = {
    socket,
    connected,
    connect,
    disconnect,
  } as any;

  return (
    <SocketContext.Provider value={values}>{children}</SocketContext.Provider>
  );
};
