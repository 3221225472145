import { AddIcon, CloseIcon, EditIcon, MinusIcon } from '@chakra-ui/icons';
import { Center, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Grid, Heading, HStack, IconButton, Input, Select, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { BiTrash } from 'react-icons/bi';
import {GiMedicines} from 'react-icons/gi';
import { Prescription } from './NewOrder'

export default function SinglePrescription({index, prescription, setPrescriptions, prescriptions, price, order} : {index: number, prescription: Prescription, setPrescriptions : ((prescriptions: Prescription[]) => void), prescriptions: Prescription [], price: boolean, order: any}) {

  const { isOpen, onOpen, onClose } = useDisclosure();

  const removePrescription = () => {

    let newPrescriptions: Prescription[] = [...prescriptions];
    newPrescriptions.splice(index, 1);
    setPrescriptions(newPrescriptions);
    

  }

  const addDrug = () => {

    let newPrescriptions: Prescription[] = [...prescriptions];
    newPrescriptions[index].drugs.push({
        title: "Drug Name",
        type: "",
        dosage: "",
        total: 0,
    })
    setPrescriptions(newPrescriptions);

  }

  const removeDrug = (pos: number) => {
    let newPrescriptions: Prescription[] = [...prescriptions];
    let drugs = [...newPrescriptions[index].drugs];
    drugs.splice(pos, 1);
    newPrescriptions[index].drugs = drugs as any;
    setPrescriptions(newPrescriptions);
  }

  return (
    <>
     <Stack>
        <HStack justify="space-between" bg={"white"} p="1" borderRadius="5px">
            <Text fontSize="xs">
              {prescription.person.name}
              {price && " $" +  prescription.drugs.reduce((acc, drug: any) => acc + drug.total, 0)}
              </Text>
            <HStack>
                <IconButton disabled={order?.status > 0} aria-label="" size="xs" onClick={onOpen}> 
                    {isOpen ? <CloseIcon /> : <EditIcon />}
                </IconButton>
                <IconButton disabled={order?.status > 0} aria-label="" size="xs" onClick={removePrescription}> 
                    <BiTrash />
                </IconButton>
            </HStack>
        </HStack>
     </Stack>
     <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{prescription.person.name}</DrawerHeader>

          <DrawerBody>
            
            <Stack spacing="5">
                <Heading fontSize="sm">Personal Details</Heading>
                <Input placeholder="Full Name" value={prescription.person.name} onChange={(e) => {
                      let newPrescriptions: Prescription[] = [...prescriptions];
                      newPrescriptions[index].person.name = e.target.value;
                      setPrescriptions(newPrescriptions);
                      
                }} />
                <Grid templateColumns={{base: "100%", md: "1fr 1fr"}} gap="10px">
                    <Input placeholder="Age" value={prescription.person.age || ""} onChange={(e) => {
                      let newPrescriptions: Prescription[] = [...prescriptions];
                      newPrescriptions[index].person.age = e.target.value;
                      setPrescriptions(newPrescriptions);
                      
                }}/>
                <Select
                    placeholder="Select Gender"
                    value={prescription.person.gender || ""} onChange={(e) => {
                      let newPrescriptions: Prescription[] = [...prescriptions];
                      newPrescriptions[index].person.gender = e.target.value;
                      setPrescriptions(newPrescriptions);
                }}
                  >
                    <option value="F">Female</option>
                    <option value="M">Male</option>
                  </Select>
                </Grid>
                <Divider />
                <HStack justify="space-between">
                 <Heading fontSize="sm">Drugs [{prescription.drugs.length}]</Heading>
                 <IconButton aria-label="" size="xs" onClick={addDrug}>
                    <AddIcon />
                 </IconButton>
                </HStack>

                {prescription.drugs.map((drug, pos) => (
                    <Stack key={pos}>
                        <HStack>
                            <Stack flexGrow="1" bg="gray.50" p="2" borderRadius="5px" boxShadow="sm">
                                <Input bg="white" fontSize="xs" placeholder="Drug Name" value={drug?.title} onChange={(e) => {

                                    let newPrescriptions: Prescription[] = [...prescriptions];
                                    let drugs = [...newPrescriptions[index].drugs];
                                    drugs[pos] = {...drugs[pos], title: e.target.value} as any
                                    newPrescriptions[index].drugs = drugs as any;
                                    setPrescriptions(newPrescriptions);

                                }}/>
                                <Input bg="white" fontSize="xs" placeholder="Type" value={drug?.type} onChange={(e) => {

                                let newPrescriptions: Prescription[] = [...prescriptions];
                                let drugs = [...newPrescriptions[index].drugs];
                                drugs[pos] = {...drugs[pos], type: e.target.value} as any
                                newPrescriptions[index].drugs = drugs as any;
                                setPrescriptions(newPrescriptions);

                                }}/>
                                <Input bg="white" fontSize="xs" placeholder="Dosage" value={drug?.dosage} onChange={(e) => {

                                let newPrescriptions: Prescription[] = [...prescriptions];
                                let drugs = [...newPrescriptions[index].drugs];
                                drugs[pos] = {...drugs[pos], dosage: e.target.value} as any
                                newPrescriptions[index].drugs = drugs as any;
                                setPrescriptions(newPrescriptions);

                                }}/>
                                {price && 
                                <Input bg="white" type="number" fontSize="xs" placeholder="Price" value={drug?.total} onChange={(e) => {

                                  let newPrescriptions: Prescription[] = [...prescriptions];
                                  let drugs = [...newPrescriptions[index].drugs];
                                  drugs[pos] = {...drugs[pos], total: parseFloat(e.target.value)} as any
                                  newPrescriptions[index].drugs = drugs as any;
                                  setPrescriptions(newPrescriptions);
  
                                  }}/>}
                                
                            </Stack>
                            <IconButton aria-label="" size="xs" onClick={() => removeDrug(pos)}>
                                <MinusIcon />
                            </IconButton>
                        </HStack>
                    </Stack>
                ))}

                {prescription.drugs.length === 0 && <Center flexDirection={"column"} minH="200px" bg="gray.100" borderRadius="5px">
                    
                        <GiMedicines size="4rem" color="green" />
                        <Text mt="10px">Add drugs for patient</Text>
                    </Center>}
    
            </Stack>

          </DrawerBody>

          
        </DrawerContent>
      </Drawer>
    </>
  )
}
