import { Button, Text, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BiSend } from 'react-icons/bi'
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../utils/axios';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'
import { Prescription } from './NewOrder'

export default function SendDraft({prescriptions, address} : {prescriptions: Prescription [], address: any}) {

  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { waId } = useParams();
  const [body, setBody] = useState("");

  const toast = useToast();

  useEffect(() => {

    let content = "*Here is your order overview*\n";
    content += "..........................\n\n";
    prescriptions.map((prescription: Prescription) => {

        content += "_*"+prescription.person.name+"*_\n";
        prescription.drugs.map((drug) => {

            content += drug?.title + " " + drug?.dosage + "\n";
            return drug;
        });

        content += "\n";


        return prescription;
    });

    content += "*Address*\n";
    content += address.address;
    content += "\n" + address?.suburb;
    content += "\n" +address?.city;
    content += "\n\n```" +address?.special+"```";

    setBody(content);

  }, [prescriptions, address]);
  const sendMessage = async() => {
    setLoading(true);

    await  axiosInstance.post("/api/message/"+waId, {
        body
    })
    .then((res) => res.data)
    .then((data) => {
        // addMessage(data);            
       onClose();
       toast({
        description: "Draft sent",
        status: "success"
       })
    })
    .catch((err) => console.log(err));

    setLoading(false);
  }
  return (
    <>
      <Button disabled={!prescriptions.length} leftIcon={<BiSend />} colorScheme="primary" size="sm" onClick={onOpen}>Send draft</Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Proof Read</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text style={{whiteSpace: 'pre-line'}} dangerouslySetInnerHTML={{__html : body}}></Text>
          </ModalBody>

          <ModalFooter>
            <Button  mr={3} variant='ghost' onClick={onClose}>
              Close
            </Button>
            <Button  colorScheme='blue' isLoading={loading} onClick={sendMessage}>Send Now</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </>
  )
}
