import { Box, Button, Divider, Grid, Heading, Input, Link, Stack, Text, Textarea } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import axiosInstance from '../../utils/axios';
import QuickResponseItem from './QuickResponseItem';

export default function QuickResponses() {

  const [loading, setLoading] = useState<boolean>(false);
  const [quickResponses, setQuickResponses] = useState<any[]>([]);

  const [title, setTitle] = useState<string>("");
  const [body, setBody] = useState<string>("");

  useEffect(() => {

    setLoading(true);
    axiosInstance.get('/api/quickresponses')
    .then((res) => res.data)
    .then((data) => {
        setLoading(false);
        setQuickResponses(data)
    })
    .catch((err) => {
        setLoading(false);
    })
  }, []);

  const createQR = async() => {
    setLoading(true);
    axiosInstance.post('/api/quickresponses', {title, body})
    .then((res) => res.data)
    .then((data) => {
        setLoading(false);
        setTitle("");
        setBody("");
        setQuickResponses((old) => [...old, data]);
    })
    .catch((err) => {
        setLoading(false);
    })
  }

  return (
    <Stack pt={{base: 0, md: 3}} spacing="20px">
        <Stack>
            <Heading fontSize="1.2em">Quick Responses</Heading>
            <Text fontSize="0.8em" lineHeight="1.4" maxWidth={{base: "100%", md: "50%"}}>Update application quick responses, please note the system uses the title to search for quick responses to display to agents in real time chat</Text>

        </Stack>
        <Divider />
        <Grid templateColumns={{base: "100%", md: "1fr 1fr"}} gap="10px">
            <Stack spacing="13px">
                <Heading size="xs" fontWeight="500">Add New Quick Response</Heading>
                <Input placeholder="Searchable Title" borderColor="primary.100" fontSize="sm" value={title} onChange={(e:any) => {
                    setTitle(e.target.value);
                }} />
                <Textarea placeholder="Quick response text"  borderColor="primary.100" fontSize="sm"value={body} onChange={(e:any) => {
                    setBody(e.target.value);
                }} />
                            <Text fontSize="0.8em">Click <Link href="https://thebig.co/whatsapp-automatic-text-formatter/" isExternal><Text as="span" color="red.500" fontWeight="bold">here</Text></Link> to get formated whatsapp messages</Text>
                <Box>
                    <Button colorScheme={"cyan"} size="sm" isLoading={loading} disabled={!title || !body} onClick={createQR}>Create</Button>
                </Box>
            </Stack>

            <Stack pt="5">
                {quickResponses.map((response, index) => (
                    <QuickResponseItem 
                        response={response} 
                        responses={quickResponses}
                        key={response.uuid} 
                        setQuickResponses={setQuickResponses}
                        pos={index as number}
                    />
                ))}
            </Stack>
        </Grid>
    </Stack>
  )
}
