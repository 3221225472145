import { IconButton } from "@chakra-ui/button";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { useDisclosure } from "@chakra-ui/hooks";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input";
import { Stack } from "@chakra-ui/layout";
import { Collapse } from "@chakra-ui/transition";
import React, { useRef } from "react";
import { BsImage, BsMic, BsPaperclip, BsPinMap } from "react-icons/bs";
import { IoDocumentOutline } from "react-icons/io5";

function Attach({ setFile, setType }: {setFile: any, setType: any}) {
  const { isOpen, onToggle } = useDisclosure();
  const mediaFile: any = useRef(null);
  const audioFile: any = useRef(null);

  const getBase64 = (file: any, cb: any ) => {
    console.log(file);
    let reader = new FileReader();
    try {
      reader.readAsDataURL(file);
    } catch (e) {
      console.log(e);
    }

    reader.onload = function () {
      console.log(reader);
      cb({ base64: reader.result, type: file.type });
      if (file.type.includes("image/")) {
        setType("media");
      } else if (file.type.includes("audio/")) {
        setType("audio");
      } else if (file.type.includes("video/")) {
        setType("video");
      }
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  return (
    <Stack position="relative" spacing="0">
      <Collapse in={isOpen} startingHeight={0}>
        <Stack position="absolute" bottom="60px" left="-8px">
          <IconButton
            aria-label=""
            shadow="lg"
            colorScheme="green"
            icon={<IoDocumentOutline />}
            size="lg"
            borderRadius="full"
          />
          <IconButton
            aria-label=""
            shadow="lg"
            colorScheme="orange"
            icon={<BsPinMap />}
            size="lg"
            borderRadius="full"
          />
          <IconButton
            aria-label=""
            shadow="lg"
            colorScheme="blue"
            icon={<BsMic />}
            size="lg"
            borderRadius="full"
            onClick={() => {
              onToggle();

             audioFile.current.click();
            }}
          />

          <IconButton
            aria-label=""
            // as={Input}
            shadow="lg"
            colorScheme="purple"
            icon={<BsImage />}
            size="lg"
            borderRadius="full"
            onClick={() => {
              onToggle();

               mediaFile.current.click();
            }}
          />
        </Stack>
        <Input
          type="file"
          id="file"
          accept="image/*, video/*"
          onChange={(e) => {
            if (mediaFile.current) {
              getBase64(mediaFile.current.files[0], setFile);
            } else {
              setFile(null);
              setType("chat");
            }
          }}
          ref={mediaFile}
          style={{ display: "none" }}
        />
        <Input
          type="file"
          id="audiofile"
          accept="audio/mp3"
          onChange={(e) => {
            if (audioFile.current) {
              getBase64(audioFile.current.files[0], setFile);
            } else {
              setFile(null);
              setType("chat");
            }
          }}
          ref={audioFile}
          style={{ display: "none" }}
        />
      </Collapse>
      <IconButton
       aria-label=""
        _hover={{
          color: useColorModeValue("gray.800", "gray.300"),
          bg: useColorModeValue("gray.200", "gray.700"),
        }}
        size="sm"
        color={useColorModeValue("gray.600", "gray.500")}
        onClick={onToggle}
        icon={<BsPaperclip size="18px" />}
      />
    </Stack>
  );
}

export default Attach;
