import React from 'react'
import {Button} from "@chakra-ui/react";
import { useAuth } from '../utils/auth/AuthContext';

export default function Dashboard() {
    const {logout}: any = useAuth();
  return (
    <Button onClick={logout}>Logout</Button>
  )
}
