import { Box, Center, Circle, Grid, Select, Spinner, Stack, useColorModeValue } from '@chakra-ui/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import SingleChatMessage from '../../elements/chat/SingleChatMessage';
import axiosInstance from '../../utils/axios';
import { useSocket } from '../../utils/sockets/useSocket';
import ChatActions from './actions/ChatActions';
import SendBox from './SendBox';

export default function ChatContainer() {

  const [messages, setMessages] = useState<any []>([]);
  const [conversations, setConversations] = useState([]);
  const [converse, setConverse] = useState<any>();
  const [loading] = useState(false);
  const [hasPrev] = useState(false);

  const { waId } = useParams();

  const messagesEndRef: any = useRef();
  const messagesTopRef: any = useRef();



  const {socket}: any = useSocket();



  useEffect(() => {

    axiosInstance
    .get('/api/conversations/' + waId)
    .then((res) => res.data)
    .then((data) => {
        if("conversations" in data){
            setConversations(data.conversations);
            if(data.conversations.length){
                const body = data.conversations[0].body;
                setConverse(data.conversations[0]);
                setMessages(body);
                setTimeout(() => {
                    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
                }, 500);
            }
        }
        
    })
    .catch((err) => {
        console.log(err);
    });
    return () => {

    }
  }, [waId]);


  const addMessage = useCallback((message: any) => {
    const oldMessages: any [] = [...messages];
    oldMessages.push(message);
    setMessages((oldMessages) => [...oldMessages, message]);
    setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 500);
  }, [messages])


  const readMessages = useCallback(async() => {
     socket.emit("read", waId);
  }, [socket, waId]);


    //   Socket Implementation
   useEffect(() => {
    
    if(waId && socket){
        //listen to messages particular to this chat
        readMessages();
        socket.on(waId, (data: any) => {
            addMessage(data);
            readMessages();
        });
    }

    return () => {
        //stop listening to this number
        if(socket){
            socket.removeAllListeners(waId || "none");
        }
       
    }
   }, [waId, socket, addMessage, readMessages]);


  return (
   <Grid h="100vh" w="100%" templateColumns={"8fr 4fr"}>
    <Stack
      // bg={useColorModeValue("#3e3e3e", "transaparent")}
      bg="rgb(255 250 245 / 94%)"
      backgroundAttachment={"fixed"}
      // bgImage={"}
      h="100%"
      w="100%"
      overflowY="scroll"
      overflowX="hidden"
      position={"relative"}
      // maxW="1000px"
    //   onScroll={handleScroll}
    >
      <img
        className="demo-bg"
        style={{
          opacity: 0.05,
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        src={process.env.PUBLIC_URL + "/chatbg-light.png"}
        alt=""
      ></img>
       <Box p="2" as={"div"} position="sticky" top="0" zIndex="9">
       <Select  bg="white" fontSize={"xs"} borderColor="transparent" outlineColor={"transparent"} _focus={{borderColor: "transparent", outlineColor: "transparent"}}  boxShadow="lg" value={converse?.id || ""} onChange={(e) => {
                  let newConverse: any = conversations.filter((conversation: any) => conversation.id === parseInt(e.target.value))[0];
                  setConverse(newConverse);
                  setMessages(newConverse.body);
                  setTimeout(() => {
                    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
                }, 500);
       }}>
          {conversations.map((conversation: any) => (
              <option key={conversation.id} value={conversation.id} >
                  {conversation.createdAt.substring(0,16).replace("T", " ")} - {conversation.updatedAt.substring(0,16).replace("T", " ")} -------------------------------- Last Message : {conversation.body[conversation.body.length - 1].body || conversation.body[conversation.body.length - 1].Body || ""}
               </option>
          ))}
        </Select>
       </Box>
      <Stack p="0" pb={5} flexGrow="1" spacing="20px" position="relative">
       

        <Center display={loading ? "flex" : "none"}>
          <Circle bg={useColorModeValue("gray.100", "gray.700")} p="1">
            <Spinner color={useColorModeValue("red.500", "red.300")} />
          </Circle>
        </Center>
        {hasPrev && <Box w="100%" ref={messagesTopRef}></Box>}

        <Box
          style={{ marginTop: "0 !important" }}
          backgroundImage={`url(${
            process.env.PUBLIC_URL
          }/chatbg-${useColorModeValue("light", "dark")}.png)`}
        ></Box>
        {messages.map((message, index) => (
            <SingleChatMessage chat={message} key={index} />
        ))}
        <Box ref={messagesEndRef}></Box>
      </Stack>
      <SendBox waId={waId} addMessage={addMessage} />
    </Stack>
    <Stack
      bg={useColorModeValue("gray.100", "gray.800")}
      h="100%"
      w="100%"
      overflowY="scroll"
      overflowX="hidden"
      spacing="40px"
    >
      <ChatActions />
    </Stack>
  </Grid>
  )
}
