import {
    Center,
    Circle,
    HStack,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
  } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../utils/axios";
import NewOrder from "../orders/NewOrder";
import OrdersList from "../orders/OrdersList";
import EditCustomer from "./EditCustomer";

  
  export default function ChatActions() {

    const [orders, setOrders] = useState({count: 0, page: 1, pages: 1, orders: []});
    const {waId} = useParams();

    useEffect(() => {
      axiosInstance.get('/api/orders/'+waId+"/1")
      .then((res) => res.data)
      .then((data) => {
        setOrders(data);
      })
      .catch((err) => console.log(err));

    }, [waId]);


    return (
      <Stack>
        <EditCustomer />
        <Tabs isFitted p="2" variant="unstyled">
          <TabList>
            <Tab
              _selected={{ color: "white", bg: "gray.700" }}
              bg="white"
              borderLeftRadius="0.3em"
              shadow="md"
            >
              <Stack>
                <HStack>
                  <Text fontSize="0.8em">New Order</Text>
                </HStack>
              </Stack>
            </Tab>
            <Tab
              _selected={{ color: "white", bg: "gray.700" }}
              borderRightRadius="0.3em"
              shadow="md"
              bg="white"
            >
              <HStack>
                <Text fontSize="0.8em">Orders</Text>
                <Circle
                  color="white"
                  bg="gray.900"
                  size="1.4em"
                  shadow="sm"
                >
                  <Center>
                    <Text fontSize="0.6em">{orders.count}</Text>
                  </Center>
                </Circle>
              </HStack>
            </Tab>
          </TabList>
  
          <TabPanels>
            <TabPanel h="100%" p={0}>
              <NewOrder setOrders={setOrders} orders={orders}/>
            </TabPanel>
            <TabPanel h="100%" p={0}>
              <OrdersList orders={orders} setOrders={setOrders} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    );
  }
  