import {
    Button,
    Center,
    FormControl,
    Image,
    Input,
    Stack,
    useToast,
  } from "@chakra-ui/react";
import  { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../utils/auth/AuthContext";

  
function Login() {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { login, errors } : any = useAuth();
  
    const [searchParams]: any = useSearchParams();
    //let newSearchParams = new URLSearchParams(params);
    let navigate = useNavigate();
    const toast = useToast();
  
    const handleLogin = async () => {
      setLoading(true);
      try {
        let res = await login(email, password);

        if (searchParams && searchParams.get("redirect")) {
          navigate(searchParams.get("redirect"));
        } else {
          navigate("/");
        }
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
        toast({
          description: errors[err.code],
          status: "error",
          position: "top-right",
        });
      }
    };
  
    return (
      <Center h="100%">
        <Stack maxW={"350px"} w="100%" align="center" spacing="6">
          <Image
            maxW="150px"
            src={process.env.PUBLIC_URL + "/logo.png"}
            alt="logo"
          />
          <Stack w="100%" spacing="4">
            <FormControl>
              <Input
                placeholder="email"
                type="email"
                bg="white"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </FormControl>
            <FormControl>
              <Input
                placeholder="passwword"
                type="password"
                bg="white"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </FormControl>
  
            <Button
              colorScheme="primary"
              disabled={!email || !password || loading}
              isLoading={loading}
              onClick={handleLogin}
            >
              Login
            </Button>
          </Stack>
        </Stack>
      </Center>
    );
  }
  
  export default Login;
  