import {
    Center,
    Circle,
    HStack,
    Image,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useColorModeValue,
  } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useAuth } from "../../utils/auth/AuthContext";
import ChatListView from "./ChatListView";

export default function ChatList() {

  const { currentUser }: any = useAuth();
  const conversations = useSelector((state: any) => state.conversations.value);
  return (
    <Stack
      bg={useColorModeValue("gray.100", "gray.800")}
      spacing="0"
      w="300px"
      minW="300px"
      h="100%"
      overflowY="scroll"
    >
      <HStack p="2" align="center">
        <Link to="/chat">
            <Image src={"/logo.png"} maxW="5em" />
        </Link>
    
        <Stack flexGrow="1" textAlign="right" spacing="0">
          <Text fontSize="xl" fontWeight="thin" color="primary.100">
            Hello
          </Text>

          <Text fontWeight="light" fontSize="xs" color="#515151">
            {currentUser.name ? currentUser.name : currentUser.email}
          </Text>
        </Stack>
      </HStack>
      <Tabs isFitted p="0" variant="unstyled">
        <TabList p="2">
          <Tab
            _selected={{ color: "white", bg: "gray.700" }}
            bg="white"
            borderLeftRadius="0.3em"
            shadow="md"
          >
            <HStack>
              <Text fontSize="0.8em">UNREAD</Text>
              <Circle
                bg="gray.900"
                size="1.4em"
                color="white"
                shadow="sm"
              >
                <Center>
                  <Text fontSize="0.6em">{conversations.unread.length}</Text>
                </Center>
              </Circle>
            </HStack>
          </Tab>
          <Tab
            _selected={{ color: "white", bg: "gray.700" }}
            borderRightRadius="0.3em"
            shadow="md"
            bg="white"
          >
            <HStack>
              <Text fontSize="0.8em">READ</Text>
              <Circle
                color="white"
                bg="gray.900"
                size="1.4em"
                shadow="sm"
              >
                <Center>
                  <Text fontSize="0.6em">{conversations.read.length}</Text>
                </Center>
              </Circle>
            </HStack>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel h="100%" p={0}>
            <ChatListView chatitems={conversations.unread} />
          </TabPanel>
          <TabPanel h="100%" p={0}>
            <ChatListView chatitems={conversations.read} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  )
}
