import { Badge, Box, Center, Heading, HStack, Spinner, Stack, Text, Wrap } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../utils/axios";

export default function SingleOrder() {

    const { uuid } = useParams();
    const [order, setOrder] = useState<any>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        axiosInstance.get(`/api/orders/${uuid}`)
        .then((res) => res.data)
        .then((data) => {
            setOrder(data);
            setLoading(false);
        })
        .catch((err) => {
            console.log(err);
            setLoading(false);
        });
    }, [uuid]);
    return (
        <Box p={{base: "2", md: "10"}} w="100%" overflow={"auto"} maxH="100vh" >
            {loading && <Center><Spinner /></Center>}
            {order && (
              <Stack spacing={{base: "3", md: 3}} h="100%">
                    <HStack w="100%" justify={"space-between"}>
                        <Heading size="2.5em">Order {uuid}</Heading>
                    </HStack>
                    <HStack py="5"  color="gray.500" fontSize={"sm"} justify="space-between">
                        <Text>
                            Created: {order?.createdAt} 
                        </Text>
                        {order && <Wrap>
                            {order.status === 0 ? <Badge colorScheme="orange" p="1" fontSize="xs">Pending</Badge> : null}
                            {order.status === 1 ? <Badge colorScheme="cyan" p="1" fontSize="xs">Quoted</Badge> : null}
                            {order.status === 3 ? <Badge colorScheme="blue" p="1" fontSize="xs">Paid</Badge> : null}
                            {order.status === 4 ? <Badge colorScheme="green" p="1" fontSize="xs">Delivered</Badge> : null}
                            {order.status === 5 ? <Badge colorScheme="red" p="1" fontSize="xs">Cancelled</Badge> : null}
                        </Wrap>}
                        
                    </HStack>
                    <Wrap>
                        
                    </Wrap>
                </Stack>
              
            )}
            
        </Box>
    );
}