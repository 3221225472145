import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthContext";

export const ProtectedRoute = (
    {children }: {children: JSX.Element}
  ) => {

    const { currentUser }: any = useAuth();
   
    const { pathname } = useLocation();

    return currentUser ? (
      children
    ) : pathname !== "/" ? (
      <Navigate to={`/auth?redirect=${pathname}`} />
    ) : (
      <Navigate to={`/auth`} />
    );
};