import {  Divider,  Stack, Text } from '@chakra-ui/react'
import OrderItem from './OrderItem'

export default function OrdersList({orders, setOrders}: {orders: any, setOrders: (orders: any) => void}) {


  return (
    <Stack pt={5} px="1">
        <Text fontSize="xs">Showing {orders.orders.length} of {orders.count} orders</Text>
        <Divider />
        {orders.orders.map((order: any, index: number) => (
          <OrderItem key={order.id} order={order} setOrders={setOrders} index={index}/>
        ))}
    </Stack>
  )
}
