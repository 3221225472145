import { ChakraProvider } from '@chakra-ui/react';
import ReactDOM from 'react-dom/client';
import App from './App';
import theme from './utils/theme';
import { Provider } from "react-redux";
import { store } from './utils/redux/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <ChakraProvider resetCSS={true} theme={theme}>
      <Provider store={store}>
         <App />
      </Provider>
    </ChakraProvider>
);