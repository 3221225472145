import { HStack, Input, InputGroup, InputRightElement, Spinner, useColorModeValue } from '@chakra-ui/react'
import React, { useState } from 'react'
import { MdSend } from "react-icons/md";
import ChatListItem from './ChatListItem';

export default function ChatListView({chatitems}: {chatitems: any []}) {

  const [phone, setPhone] = useState("");  
  const [loading] = useState(false);
  return (
    <>
     <HStack p={3} position="sticky" top="0" zIndex="1">
        <InputGroup>
          <Input
            bg={useColorModeValue("gray.50", "gray.700")}
            color={useColorModeValue("gray.700", "gray.50")}
            placeholder="Search Phone / Name"
            borderRadius="20px"
            shadow="sm"
            fontSize="0.8em"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            borderColor={useColorModeValue("gray.300", "gray.500")}
          />
          {phone.length > 9 && (
            <InputRightElement
              cursor="pointer"
              color="green.300"
              children={<MdSend />}
            />
          )}
          {loading && (
            <InputRightElement
              cursor="pointer"
              color="gray.300"
              children={<Spinner />}
            />
          )}
        </InputGroup>
      </HStack>
      {chatitems.length > 0 &&
        chatitems.map((chat, index) => (
          <ChatListItem chatItem={chat} key={index} />
        ))}
    </>
  )
}
