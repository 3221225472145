import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Divider, Heading, HStack, Stack, Text, Textarea, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import axiosInstance from '../../utils/axios';

export default function TemplateList({settings}: {settings: any}) {

  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const saveSettings = () => {

    setLoading(true);

    axiosInstance.post("/api/settings", settings.settings)
      .then((res) => res.data)
      .then((data) => {
        setLoading(false);
        toast({
          description: "Templates updated successfully",
          status: "success",
        });
      }).catch((err) => {
        console.log(err)
        setLoading(false);
      });

  }
  return (
    <Stack pt={{base: 0, md: 3}} spacing="20px">
      <Stack>
          <HStack justify={"space-between"}>
             <Heading fontSize="1.2em">Message Templates</Heading>
             <Button size="sm" onClick={saveSettings} isLoading={loading}  colorScheme={"primary"}>Save</Button>
          </HStack>
         
          <Text fontSize="0.8em" lineHeight="1.4" maxWidth={{base: "100%", md: "50%"}}>Update application message templates</Text>
      </Stack>
      <Divider />
      <Accordion allowToggle>
        {settings && settings.settings && settings.settings.templates && Object.entries(settings.settings.templates).map(([key, value]: any, index: number) => (
            <AccordionItem border="none" key={index}>
            <AccordionButton borderRadius={"md"} bg="white" border="none" boxShadow={"sm"} mb="2">
                <Box flex='1' textAlign='left'>
                  {key}
                </Box>
                <AccordionIcon />
            </AccordionButton>
    
            <AccordionPanel pb={4}>
              <Textarea value={value} rows={10} bg="white" p="4" onChange={(e) => {

                let newSettings = {...settings.settings};
                newSettings.templates[key] = e.target.value;

                settings.setSettings(newSettings);
              }} />
            </AccordionPanel>
          </AccordionItem>
        ))}

      </Accordion>
    </Stack>
  )
}
