import { Box, Heading, Stack } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import QuickResponses from './settings/QuickResponses'
import axiosInstance from '../utils/axios';
import TemplateList from './settings/TemplateList';

export default function SettingsPage() {


  const [settings, setSettings] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {

    setLoading(false);
    axiosInstance.get('/api/settings')
      .then((res) => res.data)
      .then((data) => {
        setSettings(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err)
        setLoading(false);
      });

  }, []);


  return (
    <Box p={{base: "2", md: "10"}} w="100%">
        <Stack spacing={{base: "3", md: 10}}>
            <Heading size="2.5em">Settings</Heading>

            <Tabs isLazy w="100%" variant='unstyled'>
                <TabList borderBottomWidth="1px">
                    <Tab fontSize="sm" transition="background 0.35s ease-in-out" borderBottomColor={"transparent"} borderBottomWidth="2px" mb={2} borderRadius="5px" _selected={{ bg: "primary.100", color: "white"  }}>Quick Responses</Tab>
                    <Tab fontSize="sm" transition="background 0.35s ease-in-out" borderBottomColor={"transparent"} borderBottomWidth="2px" mb={2} borderRadius="5px" _selected={{ bg: "primary.100", color: "white"}}>Email Settings</Tab>
                    <Tab fontSize="sm" transition="background 0.35s ease-in-out" borderBottomColor={"transparent"} borderBottomWidth="2px" mb={2} borderRadius="5px" _selected={{ bg: "primary.100", color: "white"}}>Payment Settings</Tab>
                    <Tab fontSize="sm" transition="background 0.35s ease-in-out" borderBottomColor={"transparent"} borderBottomWidth="2px" mb={2} borderRadius="5px" _selected={{ bg: "primary.100", color: "white" }}>Templates</Tab>
                </TabList>
                <TabPanels>
                    {/* initially mounted */}
                    <TabPanel>
                      <QuickResponses />
                    </TabPanel>
                    {/* initially not mounted */}
                    <TabPanel>
                    <p>two!</p>
                    </TabPanel>
                    <TabPanel>
                    <p>two!</p>
                    </TabPanel>
                    <TabPanel>
                      <TemplateList settings={{settings, setSettings}} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Stack>
        
    </Box>
  )
}
